import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';
import './404.scss';

import { PrimaryLayout } from 'components/PrimaryLayout';

const NotFoundPage: FunctionComponent = () => {
  return (
    <PrimaryLayout>
      <div className="page-styles">
        <title>Not found</title>
        <h1 className="heading-styles">Page not found</h1>
        <p className="paragraph-styles">
          Sorry{' '}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{' '}
          we couldn’t find what you were looking for.
          <br />
          {process.env.NODE_ENV === 'development' ? (
            <>
              <br />
              Try creating a page in{' '}
              <code className="code-styles">src/pages/</code>.
              <br />
            </>
          ) : null}
          <br />
          <Link to="/" aria-label="Navigate to homepage">
            Go home
          </Link>
          .
        </p>
      </div>
    </PrimaryLayout>
  );
};

export default NotFoundPage;
